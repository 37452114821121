import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isNil, isNull, size } from 'lodash';
import { InView } from 'react-intersection-observer';
import useDeviceType from '@/hooks/useDeviceType';
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap as GoogleMapBase,
  // PolylineF,
  useJsApiLoader,
} from '@react-google-maps/api';
import {
  ICoordinates,
  IGoogleMapProps,
} from '@/components/base/google-map/types';
import {
  DEFAULT_MAP_OPTIONS,
  MAP_DARK_MODE_STYLES,
  MAP_STYLES,
  TURKEY_CENTER_OF_THE_WORLD_COORDINATES,
} from '@/components/base/google-map/constants';
import { GoogleMapContext } from '@/components/base/google-map/Context';

const GoogleMap: React.FC<IGoogleMapProps> = ({
  options,
  directions: directionsData,
  onDirectionsCallback,
  children,
  ...props
}) => {
  const deviceType = useDeviceType();
  const {
    isDarkMap,
    directions,
    setDirections,
    travelMode,
    setTravelMode,
    setDirectionsDetails,
  } = useContext(GoogleMapContext);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  });

  // states
  const [isInView, setInView] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  // const [isDirectionsLoaded, setDirectionsLoaded] = useState<boolean>(false);
  const [prevDirectionsServiceOptions, setPrevDirectionsServiceOptions] =
    useState<google.maps.DirectionsRequest | null>(null);

  // data
  const mapOptions = useMemo<google.maps.MapOptions>(
    () =>
      isLoaded
        ? {
            zoom: DEFAULT_MAP_OPTIONS.zoom,
            center: TURKEY_CENTER_OF_THE_WORLD_COORDINATES,
            minZoom: deviceType === 'desktop' ? DEFAULT_MAP_OPTIONS.minZoom : 4,
            maxZoom: DEFAULT_MAP_OPTIONS.maxZoom,
            backgroundColor: 'inherit',
            fullscreenControl: deviceType !== 'mobile',
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_RIGHT,
            },
            disableDefaultUI: true,
            streetViewControl: false,
            keyboardShortcuts: deviceType !== 'mobile',
            restriction: {
              latLngBounds: {
                north: 46.0, // Expanded northern border (top)
                south: 30.0, // Expanded southern border (bottom)
                west: 10.0, // Expanded western border (left)
                east: 55.0, // Expanded eastern border (right)
              },
              strictBounds: false, // Only within the specified limits
            },
            // scrollwheel: true, // ignore: CTRL + Wheel
            styles: isDarkMap ? MAP_DARK_MODE_STYLES : MAP_STYLES,
            ...options,
          }
        : {},
    [isLoaded, deviceType] // options -> don't put it in deps because google map will rerender again in again
  );

  const selectedSingleLocation = useMemo(() => {
    const selectedAll =
      directionsData && directionsData.origin && directionsData.destination;
    return (
      directionsData &&
      !selectedAll &&
      (directionsData.origin || directionsData.destination)
    );
  }, [directionsData]);

  // actions
  const onMapUnmount = () => {
    setMap(null);
  };

  const onChangeInView = (inView: boolean) => {
    if (inView) {
      return;
    }

    setInView(inView);
  };

  const handleDirectionsDetails = (leg: google.maps.DirectionsLeg) => {
    const details: any = {
      distance: null,
      duration: null,
    };

    if (isNil(leg)) {
      setDirectionsDetails(details);
      return;
    }

    if (leg.distance) {
      details.distance = leg.distance.text;
    }

    if (leg.duration) {
      details.duration = leg.duration.text;
    }

    setDirectionsDetails(details);
  };

  const directionsServiceOptions =
    useMemo<google.maps.DirectionsRequest | null>(() => {
      if (!isLoaded || !directionsData) {
        return null;
      }

      let origin = directionsData.origin;
      let destination = directionsData.destination;
      let waypoints = directionsData.waypoints || [];

      // if only selected 1 location or selected same location
      if (
        !origin ||
        !destination ||
        (origin.lat === destination.lat && origin.lng === destination.lng)
      ) {
        const totalWaypoints = size(directionsData.waypoints);
        // if there are min 2 selected waypoints then set them as origin ~ destination
        if (directionsData.waypoints && totalWaypoints >= 2) {
          origin = directionsData.waypoints[0];
          destination = directionsData.waypoints[totalWaypoints - 1];
          waypoints = directionsData.waypoints.slice(1, -1);
        } else {
          return null;
        }
      }
      return {
        origin: origin,
        destination: destination,
        optimizeWaypoints: true,
        language: 'en',
        travelMode: !isNil(directionsData.travelMode)
          ? google.maps.TravelMode[directionsData.travelMode]
          : google.maps.TravelMode[travelMode],
        waypoints: waypoints.map((coordinates: ICoordinates) => ({
          location: coordinates,
          stopover: true,
        })),
      };
    }, [directionsData, travelMode, isLoaded]);

  const directionsCallback = useCallback(
    (
      response: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus
    ) => {
      if (status === 'OK' && response !== null) {
        const currentRoute = response.routes[0];
        const currentLeg = currentRoute.legs[0];

        const isSelectedSameLocation =
          currentLeg.distance?.value === 0 || currentLeg.duration?.value === 0;

        if (!isSelectedSameLocation) {
          setDirections(response);
          handleDirectionsDetails(currentLeg);
          onDirectionsCallback && onDirectionsCallback(currentLeg);
        } else {
          setDirections(null);
        }

        if (map && currentLeg) {
          const newBounds = currentRoute.bounds;

          const isChanged = (
            prevOptions: any,
            currentOptions: any,
            which: 'origin' | 'destination'
          ) => {
            // Check if the specified option (origin or destination) has changed
            return (
              prevOptions &&
              currentOptions &&
              (prevOptions?.[which]?.lat !== currentOptions?.[which]?.lat ||
                prevOptions?.[which]?.lng !== currentOptions?.[which]?.lng)
            );
          };

          const isOriginChanged = isChanged(
            prevDirectionsServiceOptions,
            directionsServiceOptions,
            'origin'
          );

          if (isNull(prevDirectionsServiceOptions) || isOriginChanged) {
            // if current leg distance is not grather than 100 methers
            if (
              !isNil(currentLeg.distance) &&
              currentLeg.distance.value > 100
            ) {
              map.fitBounds(newBounds);
            }
          }

          setPrevDirectionsServiceOptions(directionsServiceOptions);
        }
      } else if (status === 'ZERO_RESULTS') {
        // if the route connot be found, then revert back to the previous travel mode
        if (['TRANSIT', 'BICYCLING', 'WALKING'].includes(travelMode)) {
          setTravelMode('DRIVING');
        } else if (['DRIVING'].includes(travelMode)) {
          setTravelMode('WALKING');
        }

        alert('Rota Bulunamadı!');
      } else {
        console.error('Directions request failed due to ' + status);
      }
    },
    [map, directionsServiceOptions, prevDirectionsServiceOptions]
  );

  // effects
  useEffect(() => {
    if (map) {
      map.set('styles', isDarkMap ? MAP_DARK_MODE_STYLES : MAP_STYLES);
    }
  }, [isDarkMap]);

  useEffect(() => {
    const selectedAll =
      directionsData && directionsData.origin && directionsData.destination;
    const selectedNone =
      directionsData && !directionsData.origin && !directionsData.destination;
    const selectedSameLocations =
      selectedAll &&
      directionsData?.origin?.lat === directionsData.destination?.lat &&
      directionsData?.origin?.lng === directionsData.destination?.lng
        ? directionsData.origin
        : null;
    const selectedSingleLocation =
      directionsData &&
      (selectedSameLocations ||
        (!selectedAll &&
          (directionsData.origin || directionsData.destination)));

    // check if no selected any locations
    if (selectedNone) {
      setDirections(null);
    }

    // check if directions are not null and either origin or destination is missing
    else if (!isNull(directions) && selectedSingleLocation) {
      onDirectionsCallback && onDirectionsCallback(null);
      // if the above condition is true, set directions to null
      setDirections(null);
      setPrevDirectionsServiceOptions(null);
    }

    // if selected single origin or destination then focus it
    if (selectedSingleLocation && map) {
      map.panTo({
        lat: Number(selectedSingleLocation.lat),
        lng: Number(selectedSingleLocation.lng),
      });
      const currentMapZoom = map.getZoom();
      if (currentMapZoom) {
        map.setZoom(
          currentMapZoom > DEFAULT_MAP_OPTIONS.zoom
            ? DEFAULT_MAP_OPTIONS.minZoom
            : DEFAULT_MAP_OPTIONS.zoom - 2
        );
      }
    }
  }, [
    directionsData?.destination?.lat,
    directionsData?.destination?.lng,
    directionsData?.origin?.lat,
    directionsData?.origin?.lng,
  ]);

  useEffect(() => {
    if (isNil(directionsServiceOptions)) {
      setDirections(null);
    }
  }, [directionsServiceOptions]);

  // renders
  if (!isLoaded) {
    // error
    if (loadError) {
      console.error('Error: GoogleMap:', loadError);
      return loadError ? <div>Error loading map</div> : null;
    }

    return null;
  }

  if (isInView) {
    return <InView onChange={onChangeInView} />;
  }

  return (
    <GoogleMapBase
      onLoad={setMap}
      onUnmount={onMapUnmount}
      mapContainerClassName="google-map-embed"
      options={mapOptions}
      {...props}
    >
      {children}
      {directionsServiceOptions && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: selectedSingleLocation ? '#5CAD32' : '#1980DD',
              strokeWeight: 5,
            },
            markerOptions: {
              visible: false,
              icon: {
                url: '/images/map/marker/marker-circle-default.webp',
                scaledSize: new window.google.maps.Size(26, 26), // Marker boyutu
              },
            },
            suppressMarkers: false,
            preserveViewport: true,
          }}
        />
      )}
      {/*directions && directions.routes[0].legs[0].steps && (
        <PolylineF
          path={directions.routes[0].legs[0].steps as any}
          options={{
            strokeColor: '#00FF00',
            strokeOpacity: 1,
            strokeWeight: 5,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
          }}
        />
      )*/}
    </GoogleMapBase>
  );
};

export default GoogleMap;
