import React, { createContext, useMemo, useState } from 'react';
import { IGoogleMapContextProps, ITravelMode, ListViewModeType } from './types';
import { LOCAL_STORAGE_KEYS_GMAP } from './constants';
import { getStorage } from '@/utils';

export const GoogleMapContext = createContext<{
  isDarkMap: boolean;
  setDarkMap: React.Dispatch<React.SetStateAction<boolean>>;
  travelMode: ITravelMode;
  setTravelMode: React.Dispatch<React.SetStateAction<ITravelMode>>;
  directions: google.maps.DirectionsResult | null;
  setDirections: React.Dispatch<
    React.SetStateAction<google.maps.DirectionsResult | null>
  >;
  directionsDetails: any;
  setDirectionsDetails: React.Dispatch<React.SetStateAction<any>>;
  showPointList: boolean;
  setShowPointList: React.Dispatch<React.SetStateAction<boolean>>;
  listViewMode: ListViewModeType;
  setListViewMode: React.Dispatch<React.SetStateAction<ListViewModeType>>;
}>({
  isDarkMap: false,
  setDarkMap: () => {},
  travelMode: 'DRIVING',
  setTravelMode: () => {},
  directions: null,
  setDirections: () => {},
  directionsDetails: null,
  setDirectionsDetails: () => {},
  showPointList: false,
  setShowPointList: () => {},
  listViewMode: 'detailed',
  setListViewMode: () => {},
});

const GoogleMapContextProvider: React.FC<IGoogleMapContextProps> = ({
  children,
}) => {
  // data
  const storagedListViewMode = useMemo<ListViewModeType>(() => {
    const value = getStorage(
      LOCAL_STORAGE_KEYS_GMAP.listViewMode
    ) as ListViewModeType;
    return value || 'detailed';
  }, []);
  const storagedDarkModeStatus = useMemo(() => {
    return getStorage(LOCAL_STORAGE_KEYS_GMAP.darkMode) === '1';
  }, []);

  // states
  const [isDarkMap, setDarkMap] = useState<boolean>(storagedDarkModeStatus);
  const [travelMode, setTravelMode] = useState<ITravelMode>('DRIVING'); // default travel mode is driving
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [directionsDetails, setDirectionsDetails] = useState<any | null>(null);
  const [showPointList, setShowPointList] = useState<boolean>(true);
  const [listViewMode, setListViewMode] =
    useState<ListViewModeType>(storagedListViewMode);

  const value = {
    // states
    isDarkMap,
    setDarkMap,
    travelMode,
    setTravelMode,
    directions,
    setDirections,
    directionsDetails,
    setDirectionsDetails,
    showPointList,
    setShowPointList,
    listViewMode,
    setListViewMode,
  };

  return (
    <GoogleMapContext.Provider value={value}>
      {children}
    </GoogleMapContext.Provider>
  );
};

export default GoogleMapContextProvider;
