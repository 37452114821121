export const LOCAL_STORAGE_KEYS_GMAP = {
  listViewMode: 'google-map-list-view-mode',
  darkMode: 'google-map-dark-mode',
};

export const TURKEY_CENTER_OF_THE_WORLD_COORDINATES = {
  lat: 39.0782,
  lng: 35.0996,
};

export const DEFAULT_MAP_OPTIONS = {
  zoom: 15,
  minZoom: 6,
  maxZoom: 19,
};

export const MAP_STYLES = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#aadaff' }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#EAE0D2' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#FFFFFF' }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      { color: '#DDD2B2' }, // Sokaklar, koyu kahverengi
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#c5e1a5' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
];

export const MAP_DARK_MODE_STYLES = [
  {
    featureType: 'landscape',
    stylers: [{ color: '#d7d7d7' }],
  },
  {
    featureType: 'administrative',
    stylers: [{ color: '#9e9e9e' }, { weight: 0.2 }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#b9b9b9' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.highway',
    stylers: [{ color: '#f5f5f5' }, { weight: 0.2 }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.fill',
    stylers: [{ visibility: 'on' }, { color: '#808080' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ visibility: 'on' }, { color: '#808080' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }, // POI etiketlerini gizle
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      { visibility: 'off' }, // Transit hatları, mavi
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }, // Yol etiketlerini kapat
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'geometry',
    stylers: [
      { visibility: 'off' }, // İş yerleri gibi mekanları kapat
    ],
  },
];
